
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  line-height: 1.5715 !important;
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: 'Roboto', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
}

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;
}

button, input, select, textarea {
  font: inherit;
}