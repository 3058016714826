.neutralHTML {
  transform: none !important;
}

input[type=range] {
  pointer-events: none;
}

input[type=range]::-webkit-slider-thumb {
  pointer-events: auto;
}

.horizontal-slider {
  width: 100%;
  max-width: 500px;
}

.stats {
  top: 50% !important;
  opacity: 0.5 !important;
}

.button-add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-select-box {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}

.custom-select-box.hidden {
  display: none;
}

*, :after, :before {
    box-sizing: border-box;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-notification {
  z-index: 0 !important;
  pointer-events: none !important;
}

@media (max-width: 768px) {
  .ant-notification {
    margin-right: 0 !important
  }
}

.ant-notification-notice-wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  pointer-events: none !important
}

@media (max-width: 768px) {
  .ant-notification-notice-wrapper {
    margin-bottom: 30px !important;
    pointer-events: none !important
  }
}

.custom-notification .ant-notification-notice-message {
  color: #999 !important;
  background-color:transparent !important;
  margin-bottom: 0 !important;
  text-align: right !important;
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .custom-notification .ant-notification-notice-message {
    float: right !important;
    max-width: 280px !important;
  }
}

.custom-notification .ant-notification-notice-close {
  color: #999 !important;
  top: 8px !important;
  right: 8px !important;
}

#actions-container {
  z-index: 3 !important;
}

#drawer-content {
  z-index: 2 !important;
}
